































import { Notify,Toast,Dialog } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ScrollView from "@/components/ShareComponent/PullDownUp.vue"
import Stroage from "@/util/Storage"
import { UserType } from "@/Api/Basics/InterfaceType"
import { GetUserData } from "@/Api/Basics/index"
import { GetCollega,SetClassSuc } from "@/Api/Class/index"
import { Vue,Component, Ref } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface ListType<T=string>{
    id:T|number;
    remark:T|undefined;
    schoolCode:T;
    inShcoolYearCode:T;
    code:T;
    name:T;
    deFlag:T;
    [propsData:string]:any
}

interface CollegeType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    SearchIcon:T;
    Value:T;
    Show:boolean
    List:Array<ListType>
    Flow:boolean
    UserData:UserType

    initData():void;
    handleClickShow():void;
    handleBlur():void;
    handleClickSearch():void;
    handleBindEnter(data:ListType):any|void;
    handleIsTo():void;
    handleUpData():void;
    handleError(data:string):void;
}

@Component({ name:"College",components:{ ScrollView,HeadTop } })
export default class College extends ZoomPage implements CollegeType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    SearchIcon = require("$icon/Basics/search.png")
    Value = ""
    Show = true
    List:Array<ListType> = []
    Flow = false //  true  就是流程绑定信息
    UserData:UserType = {}

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       try{
           this.UserData = JSON.parse( Stroage.GetData_ && Stroage.GetData_("UserData") || '{}' )
       }catch(cat){}
       this.initData()
    }

    initData(){
        if( "flow" in this.$route.query && /true/.test( this.$route.query.flow as string ) ){
            this.Flow = true
        }else{
            this.Flow = false
        }
        if( this.UserData.school ){
            if( this.UserData.inShcoolYearCode ){
                this.handleUpData()
            }else{
                Dialog.confirm({
                   title: '温馨提醒',
                   message: '请先绑定入学时间',
                   confirmButtonText:"确定",
                   cancelButtonText:"不用了",
                   className:"HideClassName",
                   overlayClass:"MaskHideClassName"
               }).then( (res:any)=>{
                   this.$router.push({
                       path:"/enter",
                   })
               } ).catch( (cat:any)=>cat)
            }
        }else{
            Dialog.confirm({
               title: '温馨提醒',
               message: '请先绑定学校',
               confirmButtonText:"确定",
               cancelButtonText:"不用了",
               className:"HideClassName",
               overlayClass:"MaskHideClassName"
           }).then( (res:any)=>{
               this.$router.push({
                   path:"/school",
                   query:{
                       BackBool:"true"
                   }
               })
           } ).catch( (cat:any)=>cat)
        }

    }

    handleClickShow(){
        this.Show = false
        this.GetInputHtml.focus()
    }

    handleBlur(){
        this.Show = true
    }

    handleClickSearch(){
        console.log( '搜索内容' )
    }

    handleBindEnter(data:ListType){
        if( this.UserData.collegeCode === data.code && this.UserData.collegeName === data.name ){
            this.handleIsTo()
            return;
        }
        this.loadingShow = true
        new Promise( (reslove,reject)=>{
            SetClassSuc({
               userId:this.userId,
               token:this.token
           },{
               collegeCode:data.code,
               collegeName:data.name
           }).then(res=>{
               console.log( res )
               if( res.message.code === "200" ){
                   reslove( res )
               }else{
                   this.handleError( res.message.msg )
                   reject()
               }
           })
        } ).then(res=>{
            GetUserData({
                userId:this.userId,
                token:this.token
            }).then(res=>{
                this.loadingShow = false
                 if( res.message.code === "200" ){
                     Stroage.SetData_ && Stroage.SetData_("UserData",JSON.stringify( res.data ))
                     this.handleIsTo()
                 }else{
                     this.handleError( res.message.msg )
                 }
            })
        })

    }

    handleIsTo(){
        if( this.Flow ){
           Toast({
               message:"将跳转绑定专业",
               icon:"none",
               className:"ToastClassName",
           })
           let time = setTimeout(()=>{
               this.$router.replace({
                   path:"/major",
                   query:{
                       flow:"true"
                   }
               })
               clearTimeout( time )
           },500)
       }else{
           Toast({
               message:"绑定成功",
               icon:"none",
               className:"ToastClassName",
           })
           let time = setTimeout(()=>{
               this.$router.go(-1)
               clearTimeout( time )
           },500)
       }
    }

    handleUpData(){
        this.loadingShow = true
        GetCollega({
            userId:this.userId,
            token:this.token
        },{
            schoolCode:this.UserData.school,
            inShcoolYearCode:this.UserData.inShcoolYearCode,
        }).then(res=>{
            this.loadingShow = false
            console.log( res )
            if( res.message.code === "200" ){
                this.List = res.data
            }else{
                this.handleError( res.message.msg )
            }
        })
    }


    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

    @Ref("InputHtml")
    inputHtml!:HTMLInputElement

    get GetInputHtml(){
        return this.inputHtml
    }
}
